@import "@components/global.scss";
@import "@components/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap");

.top-container {
    margin-left: 250px;
    background-color: rgb(248, 248, 248);
    padding: 30px;
    border-bottom-width: 2px;
}

.top-container-list {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: right;
}

.title-text {
    font-size: 32px;
    font-weight: 700;
}

.license-text {
    font-size: 24px;
    font-weight: 700;
}

.memberList-button {
    margin-right: 10px;
    background-color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    border-width: 1px;
    font-weight: bold;
    border-color: #757575;
}

.member-search {
    border-width: 1px;
    border-color: #757575;
    border-radius: 4px;
    width: 80%;
    padding: 4px;
}

.member-search:focus {
    border: 2px solid;
    border-color: #0ba1ae;
}

.search-filter {
    border-width: 1px;
    border-color: #757575;
    border-radius: 4px;
    // width: 8%;
    padding: 4px;
    margin-left: 5px;
}

.search-filter:hover {
    background-color: #0ba1ae;
    color: #fff;
}

.bottom-container {
    margin-left: 250px;
    // padding: 30px;
    padding-left: 30px;
    padding-right: 30px;

}

.bottom-container-list {
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    border-bottom-width: 1px;
    padding: 10px;
    align-items: center;
}

.bottom-container-list-name {
    display: flex;
    flex-direction: row;
}

.bottom-container-list-account {
    display: flex;
    flex-direction: row;
}

// 이름
.userName {
    width: 30%;
    font-weight: bold;
}

.userEmail {
    width: 30%;
    font-weight: bold;
}

// 계정 유형
// .userType {
//     width:22%;
//     font-weight: bold;
// }
// 계정 상태
.userState {
    width: 20%;
    font-weight: bold;
}

.userAdd {
    width: 20%;
    justify-content: right;
    display: flex;
}

.box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.userInvite-button {
    // background-color: rgb(0, 122, 90);
    background-color: #0BA1AE;
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}