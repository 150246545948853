@import "@components/global.scss";
@import "@components/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap");
.side-screen {
  // position: relative;
  position: fixed;
  z-index: 1000;
  flex-grow: 0;
  width: 250px;
  // height: 100%;
  // display: flex;

  // background-color: #f4f2ef;
  // background-color: #455a64;
  // background-color: #8085DC;
  background-color: #696fe0;

  // @include tablet {
  //     width: 200px;
  // }

  .inner-side {
    width: 100%;
    height: 100vh;
    //사이드바 배경색이 이거여야 하는데 안바껴요 ㅠㅠ

    @include tablet {
      width: 100%;
    }
  }

  //h-full w-max absolute sm:relative bg-[#faf9f7] flex-col justify-between hidden sm:flex
}
.logo-img {
  width: 60px;
  height: 60px;
  margin: 20px auto auto auto;
}
.user-school {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin: -10px 20px 0 20px;
  color: #eeeeee;
  // font-weight: 600;
}
.user-division {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  height: 37px;

  color: #eeeeee;
  margin: 20px 0px 0 20px;
}
.user-info {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  // font-weight: 600;

  height: 37px;
  // margin-top: 50px;
  // margin-left: 20px;
  margin: 0px 20px 0 20px;

  // color: #686766;
  // 텍스트
  color: #eeeeee;
}
.user-mail {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  // font-weight: 600;

  height: 19px;
  margin-left: 20px;

  text-align: left;
  letter-spacing: normal;

  // 기존폰트컬러
  // color: #afaead;
  color:#bdbdbd;
  //text-sm text-[#9b9b9b]
  color: #eeeeee;
}

.home-block {
  width: 100%;
  height: 52px;
  margin-top: 40px;
  padding: 13px 0 12px 20px;
  display: flex;
  align-items: center;
  vertical-align: middle;

  // @include tablet {
  //     width: 200px;
  // }

  &:hover {
    cursor: pointer;
    // background-color: #263238;
    background-color: #2a2e73;
  }
  .img-set {
    float: left;

    width: 24px;
    height: 24px;
    // margin: 2px 8px 1px 0;

    object-fit: contain;
  }
}


.student-block {
  width: 100%;
  height: 52px;
  padding: 13px 0 12px 20px;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
    background-color: #263238;
  }
  .img-set {
    float: left;
    width: 24px;
    height: 24px;
    margin: 2px 8px 1px 0;
    object-fit: contain;
  }
}

.menu-block {
  position: relative;

  width: 100%;
  height: 52px;
  padding: 13px 0 12px 20px;

  vertical-align: middle;

  &:hover .img-set-add {
    display: block;
  }
  .img-set-add {
    position: absolute;
    top: 14px;
    right: 24px;
    bottom: 14px;

    display: block;
    float: left;

    width: 24px;
    height: 24px;

    cursor: pointer;

    object-fit: contain;
    &:hover {
      display: block;
    }
  }
}
.menu-classlist {
  width: 100%;
}
.bottom-container {
  position: absolute;
  bottom: 3vw;
  display: flex;

  width: 100%;
  margin: 30px 0;
  .setting-block {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    // padding: 13px 0 12px 20px;
    // background-color: #263238;
    // @include tablet {
    //     width: 200px;
    // }
    border-radius: 4px;
    background-color: #2a2e73;

    &:hover {
      cursor: pointer;
      background-color: #2a2e73;
      .img-set-add {
        display: block;
      }
    }

    .img-set-add {
      display: block;
      float: right;

      width: 24px;
      height: 24px;
      margin-top: 2px;
      margin-right: 5px;
      margin-bottom: 1px;

      cursor: pointer;

      object-fit: contain;
      &:hover {
        display: block;
      }
    }
  }
}

.side-bar-line {
  // margin-top: 3.7vh;
  // margin-bottom: 2.8vh;
  // margin-left: 1.5vh;
  width: 100%;
}
.side-bar-underline {
  width: 260px;
  margin-top: 3.7vh;
  margin-bottom: 2.8vh;
  margin-left: 1.5vh;

  border: 1px solid #d0cccc;
  background-color: #d0cccc;

  // @include tablet {
  //     width: 160px;
  // }
}

.img-set {
  float: left;

  width: 24px;
  height: 24px;
  // margin: 2px 8px 1px 0;
  // cursor: default;

  object-fit: contain;
}

// 사이드바 텍스트 
.span-sidebar {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  //font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  // line-height: normal;

  float: left;

  height: 24px;
  // height: 27px;
  margin: 0 0 0 8px;
  margin-top: -2px;

  cursor: pointer;
  text-align: left;
  text-align: center;
  letter-spacing: normal;

  // color: #66666e;
  color: #eeeeee;
}

.span-classList {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  // font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  line-height: 24px;

  float: left;

  height: 24px;
  margin: 0 0 0 8px;
  margin-top: 2px;

  cursor: default;
  text-align: left;
  letter-spacing: normal;

  color: #66666e;
}

.block-click {
  pointer-events: none;
}

.bottom-logout-container {
 margin-top: 2px; 
 margin-left: 15px;
}

.buttons {
  // margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  // background-color: #0BA1AE;
  background-color: #2a2e73;
  color: #fff;
  padding: 10px;
  margin-left: 20px;
}
