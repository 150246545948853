/* h1 {
  margin-left: 10%;
  margin-top: 7%;
  color: black;
  font-size: xx-large;
  font-weight: bold;
} */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap");
.container {
  display: flex;
  min-width: 1024px;
}
.modal-text {
  font-family: "Noto Sans KR", "Roboto", sans-serif;
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.Img-greeting {
  width: 100px;
  height: 100px;
}

.Img_div {
  margin: 133px 44px auto 60px;
}

.base-ground {
  margin-top: 133px;
}

.Span-greeting {
  font-size: 20px;
  line-height: normal;
  color: var(--text-black-dark);
}

.middle-text {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 44px;
  color: var(--text-black-regular);
}

@mixin input-default {
  width: 320px;
  height: 44px;
  padding: 0 20px 0 20px;
  margin-bottom: 12px;
  border: solid 1px var(--main-green-deep);
  border-radius: 16px;
  background-color: var(--main-green-light);
}

.input-with-button {
  @include input-default;
  padding: 0 60px 0 20px;
}




.flex-container {
  position: relative;
  width: 320px;
  display: flex;
  justify-content: space-between;

  .input-flex {
    @include input-default;
    width: 96px;
  }

  .select-flex {
    @include input-default;
    font-size: 14px;
    width: 96px;
  }
  .select-placeholder {
    position: absolute;
    font-size: 14px;
    left: 20px;
    top: 12px;
    color: var(--text-black-light);
  }
}

.input-normal {
  @include input-default;
}
.invite-submit {
  @include input-default;
  border: none;
  background-color: var(--main-green-regular);

  color: var(--main-green-black);
  font-size: 14px;
  font-weight: 600;
}
.input-submit {
  @include input-default;
  border: none;
  width: 100px;
  margin-left: 24px;
  background-color: var(--main-green-regular);

  color: var(--main-green-black);
  font-size: 14px;
  font-weight: 600;
}

.alarm {
  border: solid 1px var(--alarm-red-regular);
}

.Rectangle-Modal-Container {
  text-align: center;
}

.Rectangle-Modal {
  text-align: center;
  display: inline;
  margin-bottom: 10px;
  width: 300px;
  height: 30px;
  border: solid 1px #949879;
  border-radius: 16px;
  background-color: #f6f7ec;
}

.input-flex:hover {
  border: solid 2px var(--main-green-black);
}

.input-normal:hover {
  border: solid 2px var(--main-green-black);
}

.input-with-button:hover {
  border: solid 2px var(--main-green-black);
}

.input-submit:hover {
  background-color: #949879;
}

input::placeholder {
  color: var(--text-black-light);
  font-size: 14px;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../../assets/images/auth-default/ic-dropdown-24.svg")
    no-repeat 80%;
}

select::-ms-expand {
  display: none;
}

select option:first-child {
  display: none;
}
:local(.modal) {
  font-family: "Noto Sans KR", "Roboto", sans-serif;
  font-size: 18px; /* 18px */

  :global(.modal-dialog) {
  }
  :global(.modal-header) {
  }
  :global(.modal-content) {
  }
  :global(.modal-body) {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.outerContainer{
    margin-top: 10px;
}
.fillInfoBox {
}

.main-container {
  display: flex;
  align-items: center;
  margin-top: 130px;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}

.off-class-sticker {
  width: 320px;
  height: 320px;

  border-radius: 16px;
  background-color: var(--background-gray-light);
}

.img-off-class {
  width: 300px;
  height: 300px;
  margin: 10px;
}

.span-bold-notice {
  font-size: 20px;

  margin-top: 30px;
  margin-bottom: 12px;

  color: var(--text-black-dark);
}

.span-light-notice {
  font-size: 16px;

  color: var(--text-black-light);
}
.file-link {
  //width: 300px;
  width: 20%;
  min-width: 250px;
  max-width: 300px;
  height: 100vh;

  border-left: 1px solid #e4e3e0;
  background-color: #f4f2ef;
  // @include tablet {
  //     width: 200px;
  //     min-width: 200px;
  // }
}
.infoContainer {
  // background-color: pink;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 30px;
}
.infoFont {
  font-size: 14px;
  color: #000;
  margin-right: 12px;
  width: 110px;
  font-weight: 600;
  margin-left: 12px;
}
.inputContainer {
  // background-color: yellow;
  border-width: 1px;
  border-radius: 4px;
  padding: 8px;
  width: 300px;
  // background-color: rgb(246, 247, 236);
  background-color: #fff;
  border-color: #e4e3e0
}

// not working
.inputContainer:focus{
  border: 2px solid;
  border-color: rgb(0, 122, 90);
}

.inputContainer::placeholder {
  // text-align: center;
  font-size: 18px;
}
// .inputContainer::placeholder {
//   &.hide {
//     color: transparent;
//   }
// }
.dropdown-btn {
  border-width: 1px;
  border-radius: 16px;
  padding: 8px;
  background-color: rgb(246, 247, 236);
  border-color: rgb(222, 224, 126);
}
.button-input {
  color: #fff;
  // background-color: rgb(0, 122, 90);
  background-color: #0BA1AE;
  border-radius: 4px;
  padding: 8px;
  width: 150px;
  // margin-left: 360px;
  margin-left: 170px;
  margin-bottom: 15px;
  margin-top: 5px;
}
.emailRegex{
  color: red;
  margin-left: 166px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.pw-reset-button{
  color: #fff;
  background-color: #0BA1AE;
  border-radius: 4px;
  padding: 8px;
  width: 150px;
  margin-left: 170px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.pw-reset-text {
  margin-left: 34px;
  margin-bottom: 14px;
  color: #000;
  font-size: 16px;
}
.info-title {
  margin-left: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}