:root {
  --main-blue-black: #525347;
  //--main-blue-regular: #d6ddab;
  --main-blue-deep: #949879;
  --main-blue-regular: #dee07e;
  --main-blue-light: #f6f7ec;
  --background-gray-deep: #e4e3e0;
  --background-gray-regular: #f4f3f0;
  --background-gray-light: #faf9f7;
  --border-gray-regular: #cfcecd;
  --border-gray-light: #e3e3e1;
  --text-black-dark: #353433;
  --text-black-regular: #686766;
  --text-black-light: #afaead;
  --sub-yellow-light: #fff5c7;
}
