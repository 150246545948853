@import "@components/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap");
.main-container {
  display: flex;
  .settings-container {
    flex-direction: column;

    width: 100%;
    margin-left: 250px;
    padding-top: 30px;
    padding-left: 30px;
    background-color: rgb(248, 248, 248);
    .settings-title {
      font-family: "Noto Sans KR", sans-serif;
      font-size: 40px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;

      margin-bottom: 14px;

      text-align: left;
      letter-spacing: normal;

      color: var(--text-black-dark);
      display: flex;
      justify-content: center;
    }
    .subtitle {
      font-family: "Noto Sans KR", sans-serif;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;

      margin-bottom: 50px;

      text-align: left;
      letter-spacing: normal;

      color: #00000099;
      display: flex;
      justify-content: center;
    }
    .settings-maintitle {
      font-family: "Noto Sans KR";
      font-size: 34px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      float: left;
      text-align: left;
      letter-spacing: normal;

      color: var(--text-black-regular);
    }
    .settings-subtitle {
      font-family: "Noto Sans KR", sans-serif;
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      float: left;
      text-align: left;
      letter-spacing: normal;
      margin-bottom: 4px;

      color: var(--text-black-regular);
    }
    hr {
      margin-bottom: 4rem;

      background-color: #d0cccc;
    }
    .buttons {
      margin-top: 2em;
      // padding: 22px 56px 21px;

      cursor: pointer;

      border-radius: 4px;
      // background-color: var(--background-gray-regular);
      background-color: #0BA1AE;
      color: #fff;
      padding: 10px;
    }
  }
}
.pencil-icon {
  display: block;
  float: left;

  margin: 7px 10px 0 20px;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    background-color: #f4f2ef;
  }
}

.passwordChange-container {
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;

  width: min-content;
  margin-top: 30px;
  .password-input {
    font-family: "Noto Sans KR", sans-serif;

    width: 300px;
    margin: 2px;
    padding: 5px 8px;

    border: 2px solid var(--border-gray-regular);
    border-radius: 4px;
    &:focus {
      border: 2px solid var(--text-black-dark);
      outline: none;
    }
  }

  .password-button {
    width: 200px;
    margin-top: 10px;
    padding: 10px;

    cursor: pointer;

    border-radius: 4px;
    // background-color: var(--background-gray-regular);
    background-color: #0BA1AE;
    color: #fff;
  }
}
.step-title-box {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.manual {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: var(--text-black-dark);
}
.guide-video {
  font-family: "Noto Sans KR", sans-serif;
    background-color: #0BA1AE;
    padding: 4px 8px;
    border-radius: 8px;
    color: #fff;
    margin-left: 20px;
    font-size: 14px;
}
.step-box {
  font-family: "Noto Sans KR", sans-serif;
  color: var(--text-black-dark);
    border-width: 2px;
    margin-right: 30px;
    padding: 14px;
    // border-radius: 4px;
    background-color: #fff;
    border-left-width: 4px;
    border-left-color: #0BA1AE;
}
.step-box-title {
  font-family: "Noto Sans KR", sans-serif;
  color: var(--text-black-dark);
  font-weight: bold;
  margin-bottom: 8px;
}
.help-box {
  font-family: "Noto Sans KR", sans-serif;
    background-color: #0BA1AE;
    padding: 8px 12px;
    border-radius: 8px;
    color: #fff;
    // margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
}