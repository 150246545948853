@import "@components/global.scss";
@import "@components/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap");

.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    padding: 4px;
    border-bottom-width: 1px;
}

.main-container-img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    //  overflow: hidden; 
    //  background-color: pink;
}

.main-container-userName-box {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

// .main-container-userState {
//     width: 22%;
// }
.main-container-accountState {
    width: 10%;
}

.main-container-userName {
    margin-left: 8px;
}

.profileCover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.button-container {
    padding: 10px;
    width: 30%;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
}
.update-button {
    padding: 8px;
    border-radius: 4px;
    // background-color: #ff9800;
    border-width: 1px;
    border-color: #ff9800;
    // background-color: #fff9c4;
    margin-right: 8px;
    &:hover {
        // background-color: #cd7d04;
        background-color: #ff9800;
        color: #fff;
      }
}
.delete-button {
    padding: 8px;
    border-radius: 4px;
    // background-color: #f44336;
    border-width: 1px;
    border-color: #f44336;
    &:hover {
        // background-color: #c7362c;
        background-color: #f44336;
        color: #fff;
      }
}
.email-container {
    width: 30%;
}
.state-button { //계정활성
    padding: 8px;
    border-radius: 4px;
    border-width: 1px;
    border-color: #bdbdbd;
    margin-right: 8px;
    &:hover {
        background-color: #bdbdbd;
        color: red;
      }
}
.state-button2 {//계정중지
    background-color: #bdbdbd; 
    padding: 8px;
    border-radius: 4px;
    color: red;
    margin-right: 8px;
    &:hover {
        background-color: #4caf50;
        color: #fff;
    }
}
.account-state {
    color: #4caf50;
    font-weight: 700;
}
.account-state2 {

}